$darkGrey: #4e4e4e;
$lightGrey: #787878;
$white: #FFFFFF;
$black: #000000;
@import 'mixins';
/* Those styles are default and can be changed */
/* Left menu style */
#LeftMenu{
  background-color: $darkGrey;
  border-right: 1px solid $lightGrey;;
  .Menu .active{
    background-color: $lightGrey;
  }

  .left_nav_button[disabled="disabled"]{
    .articles{
      background-image:url(../../images/Webviewer_LeftNav_ArticleList_Disabled@2x.png);
      &:hover{
        background-color: $darkGrey;
        background-image:url(../../images/Webviewer_LeftNav_ArticleList_Disabled@2x.png);
      }
    }
    .search{
      background-image:url(../../images/Webviewer_LeftNav_Search_Disabled@2x.png);
      &:hover{
        background-color: $darkGrey;
        background-image:url(../../images/Webviewer_LeftNav_Search_Disabled@2x.png);
      }
    }
    .issues{
      background-image:url(../../images/Webviewer_LeftNav_IssueList_Disabled@2x.png);
      &:hover{
        background-color: $darkGrey;
        background-image:url(../../images/Webviewer_LeftNav_IssueList_Disabled@2x.png);
      }
    }
    .pages{
      background-image:url(../../images/Webviewer_LeftNav_Pagelist_Disabled@2x.png);
      &:hover{
        background-color: $darkGrey;
        background-image:url(../../images/Webviewer_LeftNav_Pagelist_Disabled@2x.png);
      }
    }
    .download{
      background-image:url(../../images/Webviewer_LeftNav_Download_Disabled@2x.png);
      &:hover{
        background-color: $darkGrey;
        background-image:url(../../images/Webviewer_LeftNav_Download_Disabled@2x.png);
      }
    }
  }
  .articles{
    background-image:url(../../images/Webviewer_LeftNav_ArticleList_Active@2x.png);
    &:hover{
      background-color: $white;
      background-image:url(../../images/Webviewer_LeftNav_ArticleList_Hover@2x.png);
    }
    &:active{
      background-color: $lightGrey;
      background-image:url(../../images/Webviewer_LeftNav_ArticleList_Click@2x.png);
    }
  }
  .search{
    background-image:url(../../images/Webviewer_LeftNav_Search_Active@2x.png);
    &:hover{
      background-color: $white;
      background-image:url(../../images/Webviewer_LeftNav_Search_Hover@2x.png);
    }
    &:active{
      background-color: $lightGrey;
      background-image:url(../../images/Webviewer_LeftNav_Search_Click@2x.png);
    }
  }
  .issues{
    background-image:url(../../images/Webviewer_LeftNav_IssueList_Active@2x.png);
    &:hover{
      background-color: $white;
      background-image:url(../../images/Webviewer_LeftNav_IssueList_Hover@2x.png);
    }
    &:active{
      background-color: $lightGrey;
      background-image:url(../../images/Webviewer_LeftNav_IssueList_Click@2x.png);
    }
  }
  .pages{
    background-image:url(../../images/Webviewer_LeftNav_Pagelist_Active@2x.png);
    &:hover{
      background-color: $white;
      background-image:url(../../images/Webviewer_LeftNav_Pagelist_Hover@2x.png);
    }
    &:active{
      background-color: $lightGrey;
      background-image:url(../../images/Webviewer_LeftNav_Pagelist_Click@2x.png);
    }
  }
  .download{
    background-image:url(../../images/Webviewer_LeftNav_Download_Active@2x.png);
    &:hover{
      background-color: $white;
      background-image:url(../../images/Webviewer_LeftNav__Download_Hover@2x.png);
    }
    &:active{
      background-color: $lightGrey;
      background-image:url(../../images/Webviewer_LeftNav_Download_Click@2x.png);
    }
  }
  .login{
    background-image:url(../../images/Webviewer_LeftNav_Login_Active@2x.png);
    &:hover{
      background-color: $white;
      background-image:url(../../images/Webviewer_LeftNav_Login_Hover@2x.png);
    }
    &:active{
      background-color: $lightGrey;
      background-image:url(../../images/Webviewer_LeftNav_Login_Click@2x.png);
    }
  }
  .logout{
    background-image:url(../../images/Webviewer_LeftNav_Logout_Active@2x.png);
    &:hover{
      background-color: $white;
      background-image:url(../../images/Webviewer_LeftNav_Logout_Hover@2x.png);
    }
    &:active{
      background-color: $lightGrey;
      background-image:url(../../images/Webviewer_LeftNav_Logout_Click@2x.png);
    }
  }
  #extend{
    border: 1px solid $lightGrey;
    border-left: 1px solid $darkGrey;
    background-color: $darkGrey;
    background-image:url(../../images/Webviewer_LeftNav_Expand_Active@2x.png);
    &:hover{
      background-color: $white;
      background-image:url(../../images/Webviewer_LeftNav_Expand_Hover@2x.png);
    }
    &:active{
      background-color: $lightGrey;
      background-image:url(../../images/Webviewer_LeftNav_Expand_Click@2x.png);
    }
  }
}

#Content{
    background-color: $darkGrey;
  canvas{
    background-color: $darkGrey;
  }
}


/* Left menu container style */
#menuData{
  background-color: $lightGrey;
  color: $white;
  font-family: 'Roboto', sans-serif;
  .searchClass{
    background-color: $lightGrey;
    color: $darkGrey;
  }
}

/* styling for the issue, content and thumbnail buttons. */
.left_nav_button {
  background-color: transparent;
  padding: 0;
  &:hover {

  }
  &:active{
    position:relative;
    top:1px;
  }
  &.disabled, &[disabled]{
    cursor: default;
  }
  i{
    width: 60px;
    height: 50px;
    display: block;
  }
}

#contentsData{
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  padding: 0 20px;
  color: $white;
  .header{
    font-weight: bold;
  }
  span:hover{
    text-decoration: underline;
  }
  .folder{
    width: 10px;
    height: 10px;
    background-size: 100% auto;
    background-image: url(../../images/Webviewer_ArticleList_Arrow_@2x.png);
    &.open{
      @include transform(90deg);
    }
  }
}

/* Issues list extend button */
#issuesData {
  button.extend {
    background: url(../../images/Webviewer_LeftNav_Expand_Active@2x.png);
    background-size: 100% auto;
    &.extended{
      background: url(../../images/Webviewer_LeftNav_contract_active@2x.png);
      background-size: 100% auto;
    }
  }
}

#thumbnailsData{
  .header{
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    color: $white;
    font-weight: bold;
  }
}

#issuesList .imageOverlay{
  width: 28px;
  height: 28px;
  bottom: 0;
  right:0;
  background: rgba(230, 230, 230, 0.8) url(../../images/Webviewer_lock.png) no-repeat center center;
}

/* Search customisation */
#searchNav #searchButton{
  padding: 4px 11px;
  color: $white;
  background-color: $black;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

#topWrapper button.extend {
  background: url(../../images/Webviewer_LeftNav_Expand_Active@2x.png);
  background-size: 100% auto;
}

#searchData{
  #searchLoading{
    height: 30px;
    background: url("../img/loader-mini-cccccc-4e4e4e.gif") no-repeat center center;
  }
  .searchImage {
    width: 65px;
    height: 65px;
  }
  .imageOverlay{
    width: 28px;
    height: 28px;
    bottom:0;
    right:0;
    background: rgba(230, 230, 230, 0.8) url(../../images/Webviewer_lock.png) no-repeat center center;
  }
  .searchResult .overlay{
    background-color: rgba(100,100,100,0.4);
  }
}

#searchList .searchResult {
  font-family: 'Roboto', sans-serif;
}

#searchGroups .searchResult{
  font-family: 'Roboto', sans-serif;
  &:hover .overlay{
    background-color: #e6e6e6;
    opacity: 0.2;
  }
}


/* Article list buttons styles */
.articles_button:hover , .articles_button:active{
  background-color: #e6e6e6;
}

.articles_button{
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  border: 1px solid;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

/* Style for folder buttons */
.articles_button .articles_folder{

}

/* Style for article buttons*/
.articles_button .articles_article{

}

/* these control the display of the top nav */
#Panel {
  padding: 3px 22px;
  background-color: rgba(000,000,000, 1);
  span {
    font-size: 14px;
    color: $white;
    font-weight: bold;
  }
}

#centerButtons{
  width: 558px;
  height: 40px;
  margin-top:-4px;
  .disabled{
    cursor: pointer;
  }
  .taskbar_btn {
    display:block;
    width: 26px;
    height: 26px;
    padding:0;
    background-size: 100% auto;
  }
  &.smaller {
    width: 518px;
  }
  .btn-group {
    display: block;
    float: left;
  }
  .dropdown-menu {
    height: 40px;
    top: 40px;
    box-sizing: border-box;
    background-color: $black;
    margin: -1px 0 0 -38px;
    padding: 0;
    border: 0;
    width: 120px;
    min-width: 120px;
    @include border-radius(0);
    .taskbar_btn{
      width:50%;
      height: 40px;
      color: $white;
    }
  }
}

.taskbar_btn_fullscreen {
  border-radius:0 !important;
  margin-right:0 !important;
  &:hover {
    border-radius:0 !important;
  }
}
.webviewer_icon{
  width: 32px;
  height: 32px;
  display: block;
}

/* First page button */
#firstPage{
  .taskbar_btn {
    background-image:url(../../images/Webviewer_GoTo_Start_Active@2x.png);
  }
  &:hover{
    background-color: $white;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_GoTo_Start_Hover@2x.png);
    }
    &.disabled{
      background-color: transparent;
    }
  }
  &:active{
    background-color: $lightGrey;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_GoTo_Start_Click@2x.png);
    }
  }
  &.disabled{
    cursor: pointer;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_GoTo_Start_Disabled@2x.png);
    }
  }
}
#previousPage{
  .taskbar_btn {
    background-image:url(../../images/WebViewer_BackOne_Active@2x.png);
  }
  &:hover{
    background-color: $white;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_BackOne_Hover@2x.png);
    }
    &.disabled{
      background-color: transparent;
    }
  }
  &:active{
    background-color: $lightGrey;
    .taskbar_btn{
      background-image:url(../../images/WebViewer_BackOne_Click@2x.png);
    }
  }
  &.disabled{
    cursor: pointer;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_BackOne_Disabled@2x.png);
    }
  }
}
#pagesInfo{

}
#nextPage{
  .taskbar_btn {
    background-image:url(../../images/WebViewer_ForwardOne_Active@2x.png);
  }
  &:hover{
    background-color: $white;
    .taskbar_btn{
      background-image:url(../../images/WebViewer_ForwardOne_Hover@2x.png);
    }
    &.disabled{
      background-color: transparent;
    }
  }
  &:active{
    background-color: $lightGrey;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_ForwardOne_Click@2x.png);
    }
  }
  &.disabled{
    cursor: pointer;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_ForwardOne_Disabled@2x.png);
    }
  }
}
/* Last page */
#lastPage{
  .taskbar_btn {
    background-image:url(../../images/Webviewer_GoTo_End_Active@2x.png);
  }
  &:hover{
    background-color: $white;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_GoTo_End_Hover@2x.png);
    }
    &.disabled{
      background-color: transparent;
    }
  }
  &:active{
    background-color: $lightGrey;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_GoTo_End_Click@2x.png);
    }
  }
  &.disabled{
    cursor: pointer;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_GoTo_End_disabled@2x.png);
    }
  }
}

#zoomOut{
  margin-left: 30px !important;
  margin-right: 5px !important;
  .taskbar_btn{
    width: 12px;
    height: 12px;
    background-image:url(../../images/Webviewer_Topbar_minus@2x.png);
  }
}
#zoomSlider{

}
#zoomIn{
  margin-right: 20px !important;
  margin-left: 5px !important;
  .taskbar_btn{
    width: 12px;
    height: 12px;
    background-image:url(../../images/Webviewer_Topbar_plus@2x.png);
  }
}

#downloadPage, #downloadSinglePage {
  .taskbar_btn {
    background-image: url(../../images/Webviewer_Download_Active@2x.png);
  }
  &:hover{
    background-color:$white;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_Download_Hover@2x.png);
    }
    &.disabled{
      background-color: transparent;
    }
  }
  &:active{
    background-color: $lightGrey;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_Download_Click@2x.png);
    }
  }
  &.disabled{
    cursor: pointer;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_Download_Disabled@2x.png);
    }
  }
}
#fullScreen{
  &.fullscreen{
    .taskbar_btn {
      background-image:url(../../images/Webviewer_ResizeDown_Active@2x.png);
    }
    &:hover{
      background-color: $white;
      .taskbar_btn{
        background-image:url(../../images/Webviewer_ResizeDown_Hover@2x.png);
      }
    }
    &:active{
      background-color: $lightGrey;
      .taskbar_btn{
        background-image:url(../../images/Webviewer_ResizeDown_Click@2x.png);
      }
    }
  }
  .taskbar_btn {
    background-image: url(../../images/Webviewer_ResizeUp_Active@2x.png);
  }
  &:hover{
    background-color: $white;
    .taskbar_btn{
      background-image:url(../../images/WebViewer_ResizeUp_Hover@2x.png);
    }
  }
  &:active{
    background-color: $lightGrey;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_ResizeUp_Click@2x.png);
    }
  }
}

#View{
  #ArrowLeft{
    background-color: $darkGrey;
    &.disabled{
      cursor: context-menu !important;
      background-color: $darkGrey !important;
      .arrows_btn_prev{
        background-image:url(../../images/Webviewer_Bigarrow_Back_Disabled@2x.png);
        background-color: $darkGrey !important;
      }
    }
    &:hover{
      .arrows_btn_prev{
        background-image:url(../../images/Webviewer_Bigarrow_Back_Hover@2x.png);
      }
    }
    &:active{
      background-color: $lightGrey;
      .arrows_btn_prev{
        background-image:url(../../images/Webviewer_Bigarrow_Back_Click@2x.png);
      }
    }
    .arrows_btn_prev{
      background-image:url(../../images/Webviewer_Bigarrow_Back_Active@2x.png);
    }
  }
  #ArrowRight{
    background-color: $darkGrey;
    &.disabled{
      cursor: context-menu !important;
      background-color: $darkGrey !important;
      .arrows_btn_next{
        background-image:url(../../images/Webviewer_Bigarrow_Forward_Disabled@2x.png);
        background-color: $darkGrey !important;
      }
    }
    &:hover{
      .arrows_btn_next{
        background-image:url(../../images/Webviewer_Bigarrow_Forward_Hover@2x.png);
      }
    }
    &:active{
      background-color: $lightGrey;
      .arrows_btn_next{
        background-image:url(../../images/Webviewer_Bigarrow_Forward_Click@2x.png);
      }
    }
    .arrows_btn_next{
      background-image:url(../../images/Webviewer_Bigarrow_Forward_Active@2x.png);
    }
  }
  .arrows_btn {
    display: inline-block;
    width: 80px !important;
    height: 80px !important;
    background-size: 100% auto;
    border:0;
    padding:0;
    border-radius:0;
    &:hover {
      background-color: $white;
    }
    &:active {
      background-color: $lightGrey;
    }
  }
}
#mobileMenu{
  .taskbar_btn {
    background-image:url(../../images/Webviewer_Mobile_Menu_Active@2x.png);
  }
/*  &:hover{
    background-color: $white;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_Mobile_Menu_Hover@2x.png);
    }
  }*/
  &:active{
    background-color: $lightGrey;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_Mobile_Menu_Click@2x.png);
    }
  }
}
#mobileArticle{
  .taskbar_btn {
    background-image:url(../../images/Webviewer_Mobile_Menu_Active@2x.png);
  }
/*
  &:hover{
    background-color: $white;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_Mobile_Menu_Hover@2x.png);
    }
  }
*/
  &:active{
    background-color: $lightGrey;
    .taskbar_btn{
      background-image:url(../../images/Webviewer_Mobile_Menu_Click@2x.png);
    }
  }
}
#article{
  .taskbar_btn{
    display:block;
    width: 26px;
    height: 26px;
    padding:0;
    background-size: 100% auto;
  }
  .article_button{
    width: 26px;
    height: 26px;
    display: block;
    background-size: 100% auto;
  }
  #previousArticle{
    background-color: $darkGrey;
    &.disabled{
      cursor: context-menu !important;
      background-color: $darkGrey !important;
      .arrow_button{
        background-image:url(../../images/Webviewer_Bigarrow_Back_Disabled@2x.png);
        background-color: $darkGrey !important;
      }
    }
    &:hover{
      background-color: $white;
      .arrow_button{
        background-image:url(../../images/Webviewer_Bigarrow_Back_Hover@2x.png);
      }
    }
    &:active{
      background-color: $lightGrey;
      .arrow_button{
        background-image:url(../../images/Webviewer_Bigarrow_Back_Click@2x.png);
      }
    }
    .arrow_button{
      background-image:url(../../images/Webviewer_Bigarrow_Back_Active@2x.png);
    }
  }
  #nextArticle{
    background-color: $darkGrey;
    &.disabled{
      cursor: context-menu !important;
      background-color: $darkGrey !important;
      .arrow_button{
        background-image:url(../../images/Webviewer_Bigarrow_Forward_Disabled@2x.png);
        background-color: $darkGrey !important;
      }
    }
    &:hover{
      background-color: $white;
      .arrow_button{
        background-image:url(../../images/Webviewer_Bigarrow_Forward_Hover@2x.png);
      }
    }
    &:active{
      background-color: $lightGrey;
      .arrow_button{
        background-image:url(../../images/Webviewer_Bigarrow_Forward_Click@2x.png);
      }
    }
    .arrow_button{
      background-image:url(../../images/Webviewer_Bigarrow_Forward_Active@2x.png);
    }
  }

  #previousArticleTop{
    &.disabled{
      cursor: context-menu !important;
      .article_button{
        background-image:url(../../images/Webviewer_BackOne_Disabled@2x.png);
      }
    }
    &:hover{
      background-color: $white;
      .article_button{
        background-image:url(../../images/Webviewer_BackOne_Hover@2x.png);
      }
      &.disabled{
        background-color: transparent;
        .article_button{
          background-image:url(../../images/Webviewer_BackOne_Disabled@2x.png);
        }
      }
    }
    &:active{
      background-color: $lightGrey;
      .article_button{
        background-image:url(../../images/WebViewer_BackOne_Click@2x.png);
      }
    }
    .article_button{
      background-image:url(../../images/WebViewer_BackOne_Active@2x.png);
    }
  }

  #nextArticleTop{
    margin-right: 15px;
    background-color: $black;
    &.disabled{
      cursor: context-menu !important;
      background-color: transparent !important;
      .article_button{
        background-image:url(../../images/Webviewer_ForwardOne_Disabled@2x.png);
        background-color: transparent !important;
      }
    }
    &:hover{
      background-color: $white;
      .article_button{
        background-image:url(../../images/WebViewer_ForwardOne_Hover@2x.png);
      }
      &.disabled{
        .article_button{
          background-image:url(../../images/Webviewer_ForwardOne_Disabled@2x.png);
        }
      }
    }
    &:active{
      background-color: $lightGrey;
      .article_button{
        background-image:url(../../images/Webviewer_ForwardOne_Click@2x.png);
      }
    }
    .article_button{
      background-image:url(../../images/WebViewer_ForwardOne_Active@2x.png);
    }
  }
  #articlePrintPage{
    background-color: $black;
    &:hover{
      background-color: $white;
      .article_button{
        background-image:url(../../images/Webviewer_Textview_Printer_Hover@2x.png);
      }
    }
    &:active{
      background-color: $lightGrey;
      .article_button{
        background-image:url(../../images/Webviewer_Textview_Printer_Click@2x.png);
      }
    }
    .article_button{
      background-image:url(../../images/Webviewer_Textview_Printer_Active@2x.png);
    }
  }
  #articleClose{
    background-color: $black;
    &:hover{
      background-color: $white;
      .article_button{
        background-image:url(../../images/Webviewer_Textview_BackToPrint_Hover@2x.png);
      }
    }
    &:active{
      background-color: $lightGrey;
      .article_button{
        background-image:url(../../images/Webviewer_Textview_BackToPrint_Click@2x.png);
      }
    }
    .article_button{
      background-image:url(../../images/Webviewer_Textview_BackToPrint_Active@2x.png);
    }
  }
  #articleFont{
    background-color: $black;
    @include border-radius(0);
    &.disabled{
      cursor: context-menu !important;
      background-color: transparent !important;
      .article_button{
        background-image:url(../../images/Webviewer_Textview_Textsize_Active@2x.png);
        background-color: transparent !important;
      }
    }
    &:hover{
      background-color: $white;
      .article_button{
        background-image:url(../../images/Webviewer_Textview_Textsize_Hover@2x.png);
      }
    }
    &:active{
      background-color: $lightGrey;
      .article_button{
        background-image:url(../../images/Webviewer_Textview_Textsize_Click@2x.png);
      }
    }
    .article_button{
      background-image:url(../../images/Webviewer_Textview_Textsize_Active@2x.png);
    }
  }
  #articleFonts{
    background-color: $black;
    button{
      background-color: $black;
      &:hover{
        background-color: $white;
        .article_font_small{
          background-image:url(../../images/Webviewer_Textview_Smalltext_Hover@2x.png);
        }
        .article_font_default{
          background-image:url(../../images/Webviewer_Textview_Mediumtext_Hover@2x.png);
        }
        .article_font_large{
          background-image:url(../../images/Webviewer_Textview_Largetext_Hover@2x.png);
        }
      }
      &:active{
        background-color: $lightGrey;
        .article_font_small{
          background-image:url(../../images/Webviewer_Textview_Smalltext_Click@2x.png);
        }
        .article_font_default{
          background-image:url(../../images/Webviewer_Textview_Mediumtext_Click@2x.png);
        }
        .article_font_large{
          background-image:url(../../images/Webviewer_Textview_Largetext_Click@2x.png);
        }
      }
      .article_font_small{
        background-image:url(../../images/Webviewer_Textview_Smalltext_Active@2x.png);
      }
      .article_font_default{
        background-image:url(../../images/Webviewer_Textview_Mediumtext_Active@2x.png);
      }
      .article_font_large{
        background-image:url(../../images/Webviewer_Textview_Largetext_Active@2x.png);
      }
    }
  }
}

/* The default text which displays when no issue is selected is styled here */
#blurBackground{
  background-color: rgba(128, 128, 128, 0.6);
  .information {
    font-family: 'Roboto', sans-serif;
    font-size:32pt;
    font-weight:300;
    color: $white;
    line-height:1.2;
  }
}

/* Slider styling */
.slider-track {
  border-color: $lightGrey;
  background-color: $black;
}
.slider-handle {
  background-color: $white;
  opacity: 1;
  &:active {
    background-color: $lightGrey;
  }
}