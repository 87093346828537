@mixin border-radius($radius){
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
}
@mixin box-sizing($box-sizing){
  -webkit-box-sizing: $box-sizing;
  -moz-box-sizing: $box-sizing;
  box-sizing: $box-sizing;
}
@mixin transform($rotate,$scale:1,$skew:1deg,$translate:0px){
  transform: rotate($rotate) scale($scale) skew($skew) translate($translate);
  -webkit-transform: rotate($rotate) scale($scale) skew($skew) translate($translate);
  -moz-transform: rotate($rotate) scale($scale) skew($skew) translate($translate);
  -o-transform: rotate($rotate) scale($scale) skew($skew) translate($translate);
  -ms-transform: rotate($rotate) scale($scale) skew($skew) translate($translate);
}